import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexView from '@/views/IndexView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    key: 'Index',
    component: IndexView
  },
  {
    path: '/Mock',
    name: 'Mock',
    key: 'Mock',
    component: () => import('@/views/MockView.vue')
  },
  {
    path: '/DynamicComponent',
    name: '动态组件',
    key: 'DynamicComponent',
    component: () => import('@/views/DynamicComponentView.vue')
  },
  {
    path: '/Recursion',
    name: '递归组件',
    key: 'Recursion',
    component: () => import('@/views/RecursionView.vue')
  },
  {
    path: '/BuiltInComponents',
    name: '内置组件',
    key: 'BuiltInComponents',
    component: () => import('@/views/BuiltInComponentsView.vue')
  },
  {
    path: '/Html2Canvas',
    name: 'Html2Canvas',
    key: 'Html2Canvas',
    component: () => import('@/views/Html2Canvas.vue')
  },
  {
    path: '/LogicFlow',
    name: 'LogicFlow绘图',
    key: 'LogicFlow',
    component: () => import('@/views/LogicFlowView.vue')
  },
  {
    path: '/OnlyOffice',
    name: 'OnlyOffice在线编辑',
    key: 'OnlyOffice',
    component: () => import('@/views/OnlyOffice.vue')
  },
  {
    path: '/Gsap',
    name: 'Gsap动画库',
    key: 'Gsap',
    component: () => import('@/views/GsapView.vue')
  },
  {
    path: '/Flex',
    name: 'Flex布局',
    key: 'Flex',
    component: () => import('@/views/FlexView.vue')
  },
  {
    path: '/ExecuteCustomCode',
    name: '执行自定义代码',
    key: 'ExecuteCustomCode',
    component: () => import('@/views/ExecuteCustomCodeView.vue')
  },
  {
    path: '/AceEditor',
    name: 'AceEditor',
    key: 'AceEditor',
    component: () => import('@/views/AceEditorView.vue')
  },
  {
    path: '/VueGridLayout',
    name: 'VueGridLayout自定义布局',
    key: 'VueGridLayout',
    component: () => import('@/views/VueGridLayoutDemo/VueGridLayoutDemoView.vue')
  },
  {
    path: '/Vuex',
    name: 'Vuex',
    key: 'Vuex',
    component: () => import('@/views/VuexView.vue')
  },
  {
    path: '/CssNonStandard',
    name: 'Css非标准值',
    key: 'CssNonStandard',
    component: () => import('@/views/CssNonStandardView.vue')
  },
  {
    path: '/AutoAdjustOverflow',
    name: '自动调整超出范围',
    key: 'AutoAdjustOverflow',
    component: () => import('@/views/AutoAdjustOverflowView.vue')
  },
  {
    path: '/DhtmlxDiagram',
    name: 'DhtmlxDiagram',
    key: 'DhtmlxDiagram',
    component: () => import('@/views/DhtmlxDiagram.vue')
  },
  {
    path: '/X6Demo',
    name: 'X6Demo',
    key: 'X6Demo',
    component: () => import('@/views/X6Demo.vue')
  },
  {
    path: '/WangEditorDemo',
    name: 'WangEditorDemo',
    key: 'WangEditorDemo',
    component: () => import('@/views/WangEditorDemo.vue')
  },
  {
    path: '/PdfJs',
    name: 'PdfJs',
    key: 'PdfJs',
    component: () => import('@/views/PdfJs.vue')
  },
  {
    path: '/VueDraggable',
    name: 'VueDraggable',
    key: 'VueDraggable',
    component: () => import('@/views/VueDraggable.vue')
  },
  {
    path: '/PriestDamageComputed',
    name: '心火龙牧伤害计算',
    key: 'PriestDamageComputed',
    component: () => import('@/views/PriestDamageComputed.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export {
  router,
  routes
}
