import { getAction } from '@/api/manage'

const user = {
  state: {
    username: ''
  },
  mutations: {
    SET_USERNAME: (state, username) => {
      state.username = username
    }
  },
  actions: {
    SetUsername: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        getAction('/user/queryById', { id: id }).then(res => {
          if (res.success) {
            commit('SET_USERNAME', `${res.result.username}-${id}`)
            resolve(res)
          } else {
            reject(res)
          }
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default user
