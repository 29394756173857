<template>
  <div>
    <a-button class="fixed-button" type="primary" shape="round" icon="code" size="large" @click="showModal"/>
    <a-modal :dialog-style="{ top: '10px' }" width="90%" v-model="visible" title="代码" :footer="null" :maskClosable="false">
      <iframe style="width: 100%;height: 85vh;border: none" :src="`/code/${fileName}.html`"/>
    </a-modal>
  </div>
</template>

<script>

export default {
  name: 'CodeView',
  components: {},
  props: {
    fileName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      visible: false
    }
  },
  methods: {
    showModal () {
      this.visible = true
    }
  }
}
</script>

<style scoped>
.fixed-button {
  position: fixed;
  top: 70px;
  right: 10px;
}
</style>
