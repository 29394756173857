<template>
  <a-layout id="components-layout-demo-top-side">
    <a-layout-header class="header">
      <div class="logo">孤独典范</div>
      <a-menu
        theme="dark"
        mode="horizontal"
        :default-selected-keys="['2']"
        :style="{ lineHeight: '64px' }"
      >
        <a-menu-item v-for="item in HeaderMenu" :key="item.key">
          <router-link :to="item.to">
            {{ item.title }}
          </router-link>
        </a-menu-item>
      </a-menu>
    </a-layout-header>
    <a-layout>
      <a-layout-sider width="200" style="background: #fff">
        <a-menu
          mode="inline"
          :selected-keys="selectedKeys"
          :open-keys="openKeys"
          :style="{ height: '100%', borderRight: 0 }"
          @openChange="handleOpenChange"
        >
          <template v-for="item in LeftMenu">
            <a-menu-item v-if="!item.children || item.children.length === 0" :key="item.key">
              <router-link :to="item.to">
                {{ item.title }}
              </router-link>
            </a-menu-item>
            <a-sub-menu v-else :key="item.key" :title="item.title">
              <a-menu-item v-for="itemChild in item.children" :key="itemChild.key">
                <router-link :to="itemChild.to">
                  {{ itemChild.title }}
                </router-link>
              </a-menu-item>
            </a-sub-menu>
          </template>
        </a-menu>
      </a-layout-sider>
      <a-layout style="padding: 0 24px 24px">
        <a-breadcrumb style="margin: 16px 0">
          <a-breadcrumb-item><router-link to="/">Home</router-link></a-breadcrumb-item>
          <template v-for="(item, index) in $route.fullPath.split('/')">
            <a-breadcrumb-item v-if="item" :key="index">{{ item }}</a-breadcrumb-item>
          </template>
        </a-breadcrumb>
        <a-layout-content style="overflow: hidden" :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: 'calc(100vh - 145px)' }">
          <router-view/>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-layout>
</template>

<script>
import { HeaderMenu, LeftMenu } from '@/config/MenuConfig'

export default {
  data () {
    return {
      selectedKeys: [],
      openKeys: [],
      LeftMenu,
      HeaderMenu,
      collapsed: false
    }
  },
  watch: {
    '$route' () {
      if (this.$route.fullPath === '/') {
        this.openKeys = []
        this.selectedKeys = ['Index']
      } else {
        const strings = this.$route.fullPath.split('/')
        if (strings.length === 2) {
          this.openKeys = []
          this.selectedKeys = [strings[strings.length - 1]]
        } else {
          this.openKeys = [strings[1]]
          this.selectedKeys = [strings[strings.length - 1]]
        }
      }
    }
  },
  created () {
    if (this.$route.fullPath === '/') {
      this.openKeys = []
      this.selectedKeys = ['Index']
    } else {
      const strings = this.$route.fullPath.split('/')
      if (strings.length === 2) {
        this.openKeys = []
        this.selectedKeys = [strings[strings.length - 1]]
      } else {
        this.openKeys = [strings[1]]
        this.selectedKeys = [strings[strings.length - 1]]
      }
    }
  },
  methods: {
    handleOpenChange (openKeys) {
      this.openKeys = openKeys
    }
  }
}
</script>

<style>
#components-layout-demo-top-side .logo {
  width: 120px;
  height: 30px;
  margin: 16px 28px 16px 0;
  float: left;
  color: #ffffff;
  line-height: 30px;
  font-size: 20px;
  font-weight: bolder;
  letter-spacing: 10px;
  user-select: none;
}
</style>
