// 引入mockjs
import Mock from 'mockjs'

// 延时200-600毫秒请求到数据
Mock.setup({
  timeout: '200-600'
})

// 配置请求拦截
Mock.mock('/mock/schematics/queryById', 'get', {
  message: '',
  success: true,
  code: 200,
  result: {
    nodes: [{
      id: '20194b1b-6033-4278-b984-a5fa250bc7e5',
      type: 'PowerStationNode',
      x: 50,
      y: 1950,
      properties: {
        text: '1FB',
        equipmentId: '1587714926951026689'
      }
    }, {
      id: '307ace3d-b6ca-4955-b3bf-5d6e79e90996',
      type: 'PowerStationNode',
      x: 200,
      y: 1950,
      properties: {
        text: '2FB',
        equipmentId: '1587733627192496129'
      }
    }, {
      id: '03107c76-ee8d-47e6-83cb-fe404545b07a',
      type: 'PowerStationNode',
      x: 400,
      y: 1950,
      properties: {
        text: '3FB',
        equipmentId: '1587980863587516417'
      }
    }, {
      id: '6f7b7998-938c-4990-a255-7ca2f80bff51',
      type: 'PowerStationNode',
      x: 550,
      y: 1950,
      properties: {
        text: '4FB',
        equipmentId: '1587980892389801985'
      }
    }, {
      id: 'b6e5ed71-83e9-46c1-a0a7-4d2306e45f60',
      type: 'PowerStationNode',
      x: 800,
      y: 1950,
      properties: {
        text: '5FB',
        equipmentId: '1587989206670540801'
      }
    }, {
      id: '3f91a569-2908-47ce-86e1-6de4111d88f3',
      type: 'TriangleNode',
      x: -100,
      y: 200,
      properties: {}
    }, {
      id: '6f2ab891-5328-4486-8bae-452d888c93e9',
      type: 'TriangleNode',
      x: 500,
      y: 200,
      properties: {
        text: '三龙I线',
        equipmentId: '1592407039320006657'
      }
    }, {
      id: '397487f1-ffbd-4f7d-942e-7ed077c60217',
      type: 'TriangleNode',
      x: 800,
      y: 200,
      properties: {
        text: '三龙II线',
        equipmentId: '1592407187311828993'
      }
    }, {
      id: '8966ac10-90b0-4b8c-b513-d2df04e6a28f',
      type: 'TriangleNode',
      x: 1100,
      y: 200,
      properties: {
        text: '三龙III线',
        equipmentId: '1592407401061949442'
      }
    }, {
      id: '72879a5d-3bc4-41c8-9b55-3f19931e65f1',
      type: 'DiamondNode',
      x: 350,
      y: 400,
      properties: {}
    }, {
      id: '6229e572-5a05-4cc9-a1ce-daadd9942abb',
      type: 'SquareNode',
      x: 350,
      y: 500,
      properties: {
        text: '5123',
        equipmentId: '159240831179006'
      },
      text: {
        x: 415,
        y: 500,
        value: '5123'
      }
    }, {
      id: '1421a890-b730-4921-939b-f7cb702eb3e5',
      type: 'DiamondNode',
      x: 350,
      y: 600,
      properties: {}
    }, {
      id: '221f1bdf-c838-40d0-8bf0-97c0dc5a8a31',
      type: 'DiamondNode',
      x: 350,
      y: 700,
      properties: {}
    }, {
      id: '89ea0083-c2ab-4682-8560-1f94a49e292c',
      type: 'SquareNode',
      x: 350,
      y: 800,
      properties: {
        text: '5122',
        equipmentId: '159240831179007'
      },
      text: {
        x: 415,
        y: 800,
        value: '5122'
      }
    }, {
      id: '56f8cb73-3ffb-44a6-b2bf-2b37c86a6b10',
      type: 'DiamondNode',
      x: 350,
      y: 900,
      properties: {}
    }, {
      id: 'ad382f70-223a-48d7-8e65-08fec9cd74a7',
      type: 'DiamondNode',
      x: 350,
      y: 1000,
      properties: {}
    }, {
      id: 'fee0a009-88a6-4705-afce-5aed2aabceb4',
      type: 'SquareNode',
      x: 350,
      y: 1100,
      properties: {
        text: '5121',
        equipmentId: '159240831179008'
      },
      text: {
        x: 415,
        y: 1100,
        value: '5121'
      }
    }, {
      id: '67b01545-7e3b-44be-b6dd-6b7a63ed6824',
      type: 'DiamondNode',
      x: 350,
      y: 1200,
      properties: {}
    }, {
      id: '57e529f3-f2dc-4096-b355-4c49b5d19eff',
      type: 'LineNode',
      x: 750,
      y: 300,
      properties: {
        nodeSize: {
          width: 1528.6000244345598,
          height: 31.636373941287488
        },
        text: 'II M',
        equipmentId: '1592421069736648706'
      }
    }, {
      id: '50be17e3-cb83-4a13-84ad-dcbd3d6cf831',
      type: 'DiamondNode',
      x: 50,
      y: 400,
      properties: {}
    }, {
      id: '430f9af4-2810-4faa-a702-c39adf572df3',
      type: 'SquareNode',
      x: 50,
      y: 500,
      properties: {
        text: '5113',
        equipmentId: '159240831179001',
        textPosition: '4'
      },
      text: {
        x: 115,
        y: 500,
        value: '5113'
      }
    }, {
      id: 'e2745607-6f54-46bb-8b1c-dd7e1a9fb862',
      type: 'DiamondNode',
      x: 50,
      y: 600,
      properties: {}
    }, {
      id: 'f02ac32f-3074-4094-8469-24726c029562',
      type: 'DiamondNode',
      x: 50,
      y: 700,
      properties: {}
    }, {
      id: 'a2feabad-afd6-48e7-bbb2-5e6f982a1d06',
      type: 'SquareNode',
      x: 50,
      y: 800,
      properties: {
        text: '5112',
        equipmentId: '159240831179002'
      },
      text: {
        x: 115,
        y: 800,
        value: '5112'
      }
    }, {
      id: 'cbeb6fd2-a36a-4a15-b71e-a8522e474bf2',
      type: 'DiamondNode',
      x: 50,
      y: 900,
      properties: {}
    }, {
      id: '6b784c00-05c5-45c8-8f90-c30f5089ffdc',
      type: 'DiamondNode',
      x: 50,
      y: 1000,
      properties: {}
    }, {
      id: 'ceb752a0-27c8-42ee-9339-604e698e2fdc',
      type: 'SquareNode',
      x: 50,
      y: 1100,
      properties: {
        text: '5111',
        equipmentId: '159240831179003',
        textPosition: '3'
      },
      text: {
        x: -15,
        y: 1100,
        value: '5111'
      }
    }, {
      id: '3f92366a-a8e5-4e24-8fec-9804c0043d6a',
      type: 'DiamondNode',
      x: 50,
      y: 1200,
      properties: {}
    }, {
      id: '2aadc8b2-c50b-4216-8d49-de1a08da36fa',
      type: 'DiamondNode',
      x: 650,
      y: 400,
      properties: {}
    }, {
      id: 'db0a86dc-f711-4d1f-8765-a1075b1e3a6c',
      type: 'SquareNode',
      x: 650,
      y: 500,
      properties: {
        text: '5133',
        equipmentId: '159240831179012'
      },
      text: {
        x: 715,
        y: 500,
        value: '5133'
      }
    }, {
      id: '0a3b901d-b254-47c8-ad4c-9aa15dc090f2',
      type: 'DiamondNode',
      x: 650,
      y: 600,
      properties: {}
    }, {
      id: '8ae1c8ad-c8ba-45f8-891a-e8041d78ab34',
      type: 'DiamondNode',
      x: 650,
      y: 700,
      properties: {}
    }, {
      id: '87fcb856-be94-4f94-bff4-a5854c67c82a',
      type: 'SquareNode',
      x: 650,
      y: 800,
      properties: {
        text: '5132',
        equipmentId: '159240831179013'
      },
      text: {
        x: 715,
        y: 800,
        value: '5132'
      }
    }, {
      id: 'd9d0c076-f1b7-44c0-9e9a-34c67db52cfe',
      type: 'DiamondNode',
      x: 650,
      y: 900,
      properties: {}
    }, {
      id: 'e01a6a50-1a29-4c74-8ff9-33f611723435',
      type: 'DiamondNode',
      x: 650,
      y: 1000,
      properties: {}
    }, {
      id: '0cf388c2-a45e-4a13-baf0-7f5a8967ab2e',
      type: 'SquareNode',
      x: 650,
      y: 1100,
      properties: {
        text: '5131',
        equipmentId: '159240831179014',
        textPosition: '3'
      },
      text: {
        x: 585,
        y: 1100,
        value: '5131'
      }
    }, {
      id: 'c3c1aa8d-d2e8-4f3c-9929-f4599538e737',
      type: 'DiamondNode',
      x: 650,
      y: 1200,
      properties: {}
    }, {
      id: '543a2677-566d-4306-ba68-37ab91b0da4f',
      type: 'DiamondNode',
      x: 950,
      y: 400,
      properties: {}
    }, {
      id: '09f299ab-789f-4a59-97cf-b9fd7d071d2d',
      type: 'SquareNode',
      x: 950,
      y: 500,
      properties: {
        text: '5143',
        equipmentId: '159240831179018'
      },
      text: {
        x: 1015,
        y: 500,
        value: '5143'
      }
    }, {
      id: 'b9a857a1-3859-4801-8a52-8026f5d921ba',
      type: 'DiamondNode',
      x: 950,
      y: 600,
      properties: {}
    }, {
      id: '821a6ce2-4399-440d-b581-d8663e32323b',
      type: 'DiamondNode',
      x: 950,
      y: 1000,
      properties: {}
    }, {
      id: '19ab6dde-3594-4fa7-929b-08fb2e93af77',
      type: 'SquareNode',
      x: 950,
      y: 1100,
      properties: {
        text: '5141',
        equipmentId: '159240831179019'
      },
      text: {
        x: 1015,
        y: 1100,
        value: '5141'
      }
    }, {
      id: '0abfeadb-0b04-4992-b69f-6121b4234cf4',
      type: 'DiamondNode',
      x: 950,
      y: 1200,
      properties: {}
    }, {
      id: '1bd1939a-b1ac-4bbd-aec9-b64bdf5d3b94',
      type: 'DiamondNode',
      x: 1250,
      y: 400,
      properties: {}
    }, {
      id: '15f105b4-7bfc-4f2d-b86c-6db7e45a0f24',
      type: 'SquareNode',
      x: 1250,
      y: 500,
      properties: {
        text: '5153',
        equipmentId: '159240831179020'
      },
      text: {
        x: 1315,
        y: 500,
        value: '5153'
      }
    }, {
      id: '503678de-0f86-4402-b536-4b8fa7e52fe7',
      type: 'DiamondNode',
      x: 1250,
      y: 600,
      properties: {}
    }, {
      id: 'a99946c5-0bc4-4b61-923b-b78f99e12f96',
      type: 'DiamondNode',
      x: 1250,
      y: 700,
      properties: {}
    }, {
      id: '2c396edb-caf4-46e6-939a-d4ebab39532b',
      type: 'SquareNode',
      x: 1250,
      y: 800,
      properties: {
        text: '5152',
        equipmentId: '159240831179021'
      },
      text: {
        x: 1315,
        y: 800,
        value: '5152'
      }
    }, {
      id: '3c9f1cad-398b-4610-a11e-e8e242be62a1',
      type: 'DiamondNode',
      x: 1250,
      y: 900,
      properties: {}
    }, {
      id: '58de9779-fad4-462b-967a-db05255810f9',
      type: 'DiamondNode',
      x: 1250,
      y: 1000,
      properties: {}
    }, {
      id: '72ff45a2-15ff-47fe-880a-d60fb7fc8641',
      type: 'SquareNode',
      x: 1250,
      y: 1100,
      properties: {
        text: '5151',
        equipmentId: '159240831179022'
      },
      text: {
        x: 1315,
        y: 1100,
        value: '5151'
      }
    }, {
      id: '005057e4-5d9d-4373-a7f2-c7f6136e025d',
      type: 'DiamondNode',
      x: 1250,
      y: 1200,
      properties: {}
    }, {
      id: 'c06aca47-24e1-47f4-adbd-b0230abe5143',
      type: 'LineNode',
      x: 750,
      y: 1250,
      properties: {
        nodeSize: {
          width: 1519.5305663930412,
          height: 31.636373941287474
        },
        text: 'I M',
        equipmentId: '1592421015097450498'
      }
    }, {
      id: 'ac3b2348-c0cc-406c-a1a6-b726d8ca5dfe',
      type: 'DiamondNode',
      x: 150,
      y: 1100,
      properties: {}
    }, {
      id: '98738656-979e-4bfd-959e-cd50193969d1',
      type: 'DiamondNode',
      x: 50,
      y: 1350,
      properties: {}
    }, {
      id: '0ce6f518-3716-4919-afcc-313523255612',
      type: 'SquareNode',
      x: 50,
      y: 1450,
      properties: {
        text: '8101',
        equipmentId: '159240831179004'
      },
      text: {
        x: 115,
        y: 1450,
        value: '8101'
      }
    }, {
      id: '159d056a-b419-4274-b7e0-d17655f7a304',
      type: 'DoubleCircleNode',
      x: 50,
      y: 1600,
      properties: {}
    }, {
      id: '66f14a11-2283-49ed-9830-2595142cddf8',
      type: 'DoubleCircleNode',
      x: 200,
      y: 1600,
      properties: {}
    }, {
      id: '17f6bd39-38b6-4960-8564-7636fa5d7309',
      type: 'SquareNode',
      x: 200,
      y: 1450,
      properties: {
        text: '8102',
        equipmentId: '159240831179005'
      },
      text: {
        x: 265,
        y: 1450,
        value: '8102'
      }
    }, {
      id: '7e3a98e0-99ba-4659-ba8f-2136a5664136',
      type: 'DiamondNode',
      x: 200,
      y: 1350,
      properties: {}
    }, {
      id: 'c6177f2d-35d5-4e0d-bf79-602a1c8210b2',
      type: 'PowerStationNode',
      x: 950,
      y: 1950,
      properties: {
        text: '6FB',
        equipmentId: '1587989239126065154'
      }
    }, {
      id: '1ab09c07-3bda-439d-8b69-6260d6246b14',
      type: 'PowerStationNode',
      x: 1250,
      y: 1950,
      properties: {
        text: '7FB',
        equipmentId: '1587989280284770306'
      }
    }, {
      id: '961933b9-b64c-4351-b86b-e551a838046d',
      type: 'PowerStationNode',
      x: 1400,
      y: 1950,
      properties: {
        text: '8FB',
        equipmentId: '1587989311335202818'
      }
    }, {
      id: '255e9803-213b-4930-ab72-8a1e9d353010',
      type: 'TriangleNode',
      x: 200,
      y: 200,
      properties: {}
    }, {
      id: '8f7067c8-0af8-4ca1-bfd5-1efa28e19f61',
      type: 'DiamondNode',
      x: 450,
      y: 950,
      properties: {}
    }, {
      id: '2e54e1c5-0a7a-4f7f-888c-d6fbf5c8e6f7',
      type: 'DiamondNode',
      x: 500,
      y: 500,
      properties: {}
    }, {
      id: 'd66f5aab-7e72-44a2-bf4d-2f4518b9f9f8',
      type: 'DiamondNode',
      x: 800,
      y: 500,
      properties: {}
    }, {
      id: '2803f38e-d738-486d-9ceb-6978ebfe499e',
      type: 'DiamondNode',
      x: 1100,
      y: 650,
      properties: {}
    }, {
      id: '9f5ed12f-9b06-4348-97d4-a3a3dac1679b',
      type: 'DiamondNode',
      x: 400,
      y: 1350,
      properties: {}
    }, {
      id: 'adaeae53-f93c-4115-8a48-67bb5c92e347',
      type: 'DiamondNode',
      x: 550,
      y: 1350,
      properties: {}
    }, {
      id: 'bef2c572-f527-4629-8731-83f984426771',
      type: 'SquareNode',
      x: 400,
      y: 1450,
      properties: {
        text: '8103',
        equipmentId: '159240831179009'
      },
      text: {
        x: 465,
        y: 1450,
        value: '8103'
      }
    }, {
      id: '1f6c72d0-1654-406c-a414-e0f9454095a2',
      type: 'SquareNode',
      x: 550,
      y: 1450,
      properties: {
        text: '8104',
        equipmentId: '159240831179010'
      },
      text: {
        x: 615,
        y: 1450,
        value: '8104'
      }
    }, {
      id: '8c6e396b-4dd8-4413-a09d-f019f8d094bf',
      type: 'DoubleCircleNode',
      x: 400,
      y: 1550,
      properties: {}
    }, {
      id: '72d6d1dc-48d0-4258-820c-fd57ea7b38c7',
      type: 'DiamondNode',
      x: 400,
      y: 1650,
      properties: {}
    }, {
      id: '91c61fe0-c09a-4278-9166-86abfce01cc7',
      type: 'SquareNode',
      x: 400,
      y: 1750,
      properties: {
        text: '8003',
        equipmentId: '159240831179011'
      },
      text: {
        x: 465,
        y: 1750,
        value: '8003'
      }
    }, {
      id: 'c8ea4d06-6a4a-4c0b-b6e6-11321b5cfa62',
      type: 'DiamondNode',
      x: 1600,
      y: 400,
      properties: {}
    }, {
      id: '8e366d43-27db-4726-825a-d0de7a8f2c94',
      type: 'SquareNode',
      x: 1700,
      y: 400,
      properties: {
        text: '5824',
        equipmentId: '159240831179025',
        textPosition: '2'
      },
      text: {
        x: 1700,
        y: 450,
        value: '5824'
      }
    }, {
      id: '8f127b91-17c6-4033-8dc6-6bcdc212473a',
      type: 'DiamondNode',
      x: 1800,
      y: 400,
      properties: {}
    }, {
      id: 'd49a80a6-d581-47b5-bd31-3f542c7181b7',
      type: 'LineNode',
      x: 2450,
      y: 300,
      properties: {
        nodeSize: {
          width: 1145.4154221803756,
          height: 33.90373845166725
        },
        text: 'IV M',
        equipmentId: '1592421233016709122'
      }
    }, {
      id: 'a1522a59-efaa-4263-af7e-51695a8b80b0',
      type: 'DiamondNode',
      x: 1600,
      y: 1200,
      properties: {}
    }, {
      id: '6505ebdf-f12c-446d-b906-8c010b77d57a',
      type: 'SquareNode',
      x: 1700,
      y: 1200,
      properties: {
        text: '5813',
        equipmentId: '159240831179026',
        textPosition: '2'
      },
      text: {
        x: 1700,
        y: 1250,
        value: '5813'
      }
    }, {
      id: 'e03de1ce-5361-4c69-8274-a268705cbd58',
      type: 'DiamondNode',
      x: 1800,
      y: 1200,
      properties: {}
    }, {
      id: 'bce7f123-e3d6-496a-a39a-e8e7e20013cb',
      type: 'DoubleCircleNode',
      x: 550,
      y: 1600,
      properties: {}
    }, {
      id: '82c41785-1fb0-4c6e-8d04-546ffe31317b',
      type: 'DoubleCircleNode',
      x: 800,
      y: 1550,
      properties: {}
    }, {
      id: 'f4cf5b44-7991-44a7-a3f4-d77e1c2ff07f',
      type: 'DoubleCircleNode',
      x: 950,
      y: 1600,
      properties: {}
    }, {
      id: '80561afa-e7f2-490f-87a2-88d064be651d',
      type: 'DoubleCircleNode',
      x: 1250,
      y: 1600,
      properties: {}
    }, {
      id: 'ea8aa0d8-e12e-4b3a-a669-9797a4b868d7',
      type: 'DoubleCircleNode',
      x: 1400,
      y: 1600,
      properties: {}
    }, {
      id: '19055d13-917f-406a-96d7-e760f989c261',
      type: 'TriangleNode',
      x: 2050,
      y: 200,
      properties: {
        text: '三江I线',
        equipmentId: '1592407493835759617'
      }
    }, {
      id: 'bb1747b5-a4c2-464f-b082-3c9a7a59d0d7',
      type: 'DiamondNode',
      x: 2200,
      y: 400,
      properties: {}
    }, {
      id: '32183f76-175a-4872-9e4c-5d3f153347c8',
      type: 'SquareNode',
      x: 2200,
      y: 500,
      properties: {
        text: '5213',
        equipmentId: '159240831179027'
      },
      text: {
        x: 2265,
        y: 500,
        value: '5213'
      }
    }, {
      id: '45fecc97-4cca-4594-8927-19a943312b02',
      type: 'DiamondNode',
      x: 2200,
      y: 600,
      properties: {}
    }, {
      id: '856c5b8c-66f1-42c3-b358-c776b4afc951',
      type: 'DiamondNode',
      x: 2200,
      y: 700,
      properties: {}
    }, {
      id: '1d636d45-4e95-4622-b2f4-4ecf10b73fb8',
      type: 'SquareNode',
      x: 2200,
      y: 800,
      properties: {
        text: '5212',
        equipmentId: '159240831179028'
      },
      text: {
        x: 2265,
        y: 800,
        value: '5212'
      }
    }, {
      id: '662b7e89-1ada-4633-832f-694b486490fe',
      type: 'DiamondNode',
      x: 2200,
      y: 900,
      properties: {}
    }, {
      id: '61d1be61-ef9b-4075-a45f-bc9c00832646',
      type: 'DiamondNode',
      x: 2200,
      y: 1000,
      properties: {}
    }, {
      id: '96b526de-53b8-458d-98dc-8ada99592509',
      type: 'SquareNode',
      x: 2200,
      y: 1100,
      properties: {
        text: '5211',
        equipmentId: '159240831179029',
        textPosition: '3'
      },
      text: {
        x: 2135,
        y: 1100,
        value: '5211'
      }
    }, {
      id: 'b3c25ab5-bddf-4f78-955d-572d2af6dcab',
      type: 'DiamondNode',
      x: 2200,
      y: 1200,
      properties: {}
    }, {
      id: 'f6833fd0-aad7-4b6c-9230-f36557fce898',
      type: 'TriangleNode',
      x: 2350,
      y: 200,
      properties: {
        text: '三江II线',
        equipmentId: '1592407542917505026'
      }
    }, {
      id: '52a311e3-e9aa-43b3-8b4d-2674c44d8383',
      type: 'TriangleNode',
      x: 2650,
      y: 200,
      properties: {
        text: '三江III线',
        equipmentId: '1592407601700675586'
      }
    }, {
      id: 'ab4440f5-dd94-43f2-a25b-a212228e1774',
      type: 'LineNode',
      x: 2450,
      y: 1250,
      properties: {
        nodeSize: {
          width: 1168.0890672841738,
          height: 31.636373941287413
        },
        text: 'III M',
        equipmentId: '1592421140897210369'
      }
    }, {
      id: 'b7b8bdcb-c144-45ba-9aea-21ea32264076',
      type: 'DiamondNode',
      x: 2500,
      y: 400,
      properties: {}
    }, {
      id: '3aa46c1d-79be-469e-9352-2051563ef618',
      type: 'SquareNode',
      x: 2500,
      y: 500,
      properties: {
        text: '5223',
        equipmentId: '159240831179032'
      },
      text: {
        x: 2565,
        y: 500,
        value: '5223'
      }
    }, {
      id: 'f22283b5-77ce-446f-90fc-962af4eade96',
      type: 'DiamondNode',
      x: 2500,
      y: 600,
      properties: {}
    }, {
      id: 'f36ef7f6-1b76-4da5-9e1c-8e25161e14a9',
      type: 'DiamondNode',
      x: 2500,
      y: 700,
      properties: {}
    }, {
      id: 'f717c43f-d468-4645-b5a6-714e3c63d926',
      type: 'SquareNode',
      x: 2500,
      y: 800,
      properties: {
        text: '5222',
        equipmentId: '159240831179033'
      },
      text: {
        x: 2565,
        y: 800,
        value: '5222'
      }
    }, {
      id: 'e4042da6-30e1-44b5-99da-6b33dd571cc0',
      type: 'DiamondNode',
      x: 2500,
      y: 900,
      properties: {}
    }, {
      id: '845ca97d-645e-4d9c-bb6a-6eb09c02a211',
      type: 'DiamondNode',
      x: 2500,
      y: 1000,
      properties: {}
    }, {
      id: '88e350fb-e60e-4e18-adcb-fd3cda1058ee',
      type: 'SquareNode',
      x: 2500,
      y: 1100,
      properties: {
        text: '5221',
        equipmentId: '159240831179034'
      },
      text: {
        x: 2565,
        y: 1100,
        value: '5221'
      }
    }, {
      id: 'c40ceecc-51e5-4cf2-b27a-d6c81e47e3a7',
      type: 'DiamondNode',
      x: 2500,
      y: 1200,
      properties: {}
    }, {
      id: '580f219c-33af-425d-88cf-21d71f9ab7db',
      type: 'DiamondNode',
      x: 2800,
      y: 400,
      properties: {}
    }, {
      id: '776b975c-1eb1-490d-af8d-1aaf307ad693',
      type: 'SquareNode',
      x: 2800,
      y: 500,
      properties: {
        text: '5233',
        equipmentId: '159240831179038'
      },
      text: {
        x: 2865,
        y: 500,
        value: '5233'
      }
    }, {
      id: '4e321f0c-567e-4929-8b90-d8f524ea4e9a',
      type: 'DiamondNode',
      x: 2800,
      y: 600,
      properties: {}
    }, {
      id: 'aa1a4092-15fe-461f-ad76-b0e468b45509',
      type: 'DiamondNode',
      x: 2800,
      y: 700,
      properties: {}
    }, {
      id: 'e3e7f5b9-520b-4dce-8d76-ee77748f75c6',
      type: 'SquareNode',
      x: 2800,
      y: 800,
      properties: {
        text: '5232',
        equipmentId: '159240831179039'
      },
      text: {
        x: 2865,
        y: 800,
        value: '5232'
      }
    }, {
      id: 'd68a6a0b-dbc0-488d-b75e-d17f4ed325ca',
      type: 'DiamondNode',
      x: 2800,
      y: 900,
      properties: {}
    }, {
      id: 'c252d383-473f-4d9a-b9d6-1df3ccff1b63',
      type: 'DiamondNode',
      x: 2800,
      y: 1000,
      properties: {}
    }, {
      id: '1a814bc5-43bb-43bc-aa57-366e3f50cfd0',
      type: 'SquareNode',
      x: 2800,
      y: 1100,
      properties: {
        text: '5231',
        equipmentId: '159240831179040',
        textPosition: '3'
      },
      text: {
        x: 2735,
        y: 1100,
        value: '5231'
      }
    }, {
      id: '81aa0988-e4f5-4e8e-a68b-874532193aab',
      type: 'DiamondNode',
      x: 2800,
      y: 1200,
      properties: {}
    }, {
      id: 'a9539af8-1c7b-492a-9dc0-c48d9c85d2c5',
      type: 'SquareNode',
      x: 1250,
      y: 1450,
      properties: {
        text: '8107',
        equipmentId: '159240831179023'
      },
      text: {
        x: 1315,
        y: 1450,
        value: '8107'
      }
    }, {
      id: '6c4bc83c-d601-4d30-ad06-97271efc1c9b',
      type: 'SquareNode',
      x: 1400,
      y: 1450,
      properties: {
        text: '8108',
        equipmentId: '159240831179024'
      },
      text: {
        x: 1465,
        y: 1450,
        value: '8108'
      }
    }, {
      id: 'af94d4a7-f35a-4172-9cb7-b5fb4655aa4f',
      type: 'DiamondNode',
      x: 1250,
      y: 1350,
      properties: {}
    }, {
      id: '11179814-3818-42ad-8ff6-470580988aac',
      type: 'DiamondNode',
      x: 1400,
      y: 1350,
      properties: {}
    }, {
      id: '09fe1a16-4d83-4da6-a259-0b44a258895b',
      type: 'PowerStationNode',
      x: 2000,
      y: 1950,
      properties: {
        text: '9FB',
        equipmentId: '1588070848412004354'
      }
    }, {
      id: '85f1eae2-8698-49f1-ba68-679356eaa917',
      type: 'PowerStationNode',
      x: 2150,
      y: 1950,
      properties: {
        text: '10FB',
        equipmentId: '1588070877692440578'
      }
    }, {
      id: '399f5747-a80b-416d-9f99-a6405e567a5f',
      type: 'DoubleCircleNode',
      x: 2000,
      y: 1600,
      properties: {}
    }, {
      id: '05ca665c-532c-4a23-b11a-d74fe512219c',
      type: 'DoubleCircleNode',
      x: 2150,
      y: 1600,
      properties: {}
    }, {
      id: '5d87d592-4963-4f76-be90-656a37a04d67',
      type: 'SquareNode',
      x: 2000,
      y: 1450,
      properties: {
        text: '8209',
        equipmentId: '159240831179030'
      },
      text: {
        x: 2065,
        y: 1450,
        value: '8209'
      }
    }, {
      id: 'e8ef31f0-ee49-4147-bfe6-460a1dec94f5',
      type: 'SquareNode',
      x: 2150,
      y: 1450,
      properties: {
        text: '8210',
        equipmentId: '159240831179031'
      },
      text: {
        x: 2215,
        y: 1450,
        value: '8210'
      }
    }, {
      id: '34d56b94-e4ec-4a83-a20c-dc9a133aa683',
      type: 'DiamondNode',
      x: 2000,
      y: 1350,
      properties: {}
    }, {
      id: 'a201f0e8-63fb-4b11-9980-c643a7a1a093',
      type: 'DiamondNode',
      x: 2150,
      y: 1350,
      properties: {}
    }, {
      id: '86c735cf-5cde-44a0-9440-3e3a7579c3fa',
      type: 'PowerStationNode',
      x: 2400,
      y: 1950,
      properties: {
        text: '11FB',
        equipmentId: '1588070905995603969'
      }
    }, {
      id: 'd4e3324b-1503-48a1-afdb-0fc3b6a6596b',
      type: 'PowerStationNode',
      x: 2550,
      y: 1950,
      properties: {
        text: '12FB',
        equipmentId: '1588070934785306626'
      }
    }, {
      id: '8197a8c4-ff87-4114-b117-0d32fb033594',
      type: 'DoubleCircleNode',
      x: 2400,
      y: 1550,
      properties: {}
    }, {
      id: 'de52f78a-9d9b-4207-b58e-b1e370df1ffe',
      type: 'DoubleCircleNode',
      x: 2550,
      y: 1600,
      properties: {}
    }, {
      id: 'b9129e70-eead-4fb6-a9d7-8d6c265420b8',
      type: 'SquareNode',
      x: 2400,
      y: 1450,
      properties: {
        text: '8211',
        equipmentId: '159240831179035'
      },
      text: {
        x: 2465,
        y: 1450,
        value: '8211'
      }
    }, {
      id: '59ce1533-b915-4c09-8db8-655892e6c696',
      type: 'SquareNode',
      x: 2550,
      y: 1450,
      properties: {
        text: '8212',
        equipmentId: '159240831179036'
      },
      text: {
        x: 2615,
        y: 1450,
        value: '8212'
      }
    }, {
      id: 'aa42b2ad-167a-4250-b22f-85e74eb64467',
      type: 'DiamondNode',
      x: 2400,
      y: 1350,
      properties: {}
    }, {
      id: 'b60005e0-1e77-4219-8f17-29f1567c516f',
      type: 'DiamondNode',
      x: 2550,
      y: 1350,
      properties: {}
    }, {
      id: 'f726a3ea-3b90-4a7f-aeac-dee134167cb6',
      type: 'PowerStationNode',
      x: 2800,
      y: 1950,
      properties: {
        text: '13FB',
        equipmentId: '1588070934319738882'
      }
    }, {
      id: '57d215db-cb75-48d5-a8e8-7129df228060',
      type: 'PowerStationNode',
      x: 2950,
      y: 1950,
      properties: {
        text: '14FB',
        equipmentId: '1588071034475524098'
      }
    }, {
      id: '562b0e7f-9db2-4ebb-a30b-6c65fb1646d0',
      type: 'DoubleCircleNode',
      x: 2800,
      y: 1550,
      properties: {}
    }, {
      id: '9a067459-9902-4302-9917-84effb46a6ce',
      type: 'DoubleCircleNode',
      x: 2950,
      y: 1600,
      properties: {}
    }, {
      id: '7b2fff39-9f56-47cc-b7ce-201f4f71d371',
      type: 'SquareNode',
      x: 2800,
      y: 1450,
      properties: {
        text: '8213',
        equipmentId: '159240831179041'
      },
      text: {
        x: 2865,
        y: 1450,
        value: '8213'
      }
    }, {
      id: '6c9d6066-4695-447e-993c-c9d5f396ed45',
      type: 'SquareNode',
      x: 2950,
      y: 1450,
      properties: {
        text: '8214',
        equipmentId: '159240831179042'
      },
      text: {
        x: 3015,
        y: 1450,
        value: '8214'
      }
    }, {
      id: '069a44f5-6078-49b1-abfc-bab0c4690ea3',
      type: 'DiamondNode',
      x: 2800,
      y: 1350,
      properties: {}
    }, {
      id: 'f7caaa40-092b-4b8b-9b50-36a140b148ef',
      type: 'DiamondNode',
      x: 2950,
      y: 1350,
      properties: {}
    }, {
      id: '70d2277c-ec05-4582-b802-84e3f10b809e',
      type: 'DiamondNode',
      x: 2900,
      y: 1100,
      properties: {}
    }, {
      id: '6b5aac9d-e986-4929-b878-73a3acc167e9',
      type: 'DiamondNode',
      x: 2600,
      y: 950,
      properties: {}
    }, {
      id: '2159e68f-6834-4601-8cb9-4b2d908988fa',
      type: 'DiamondNode',
      x: 2650,
      y: 500,
      properties: {}
    }, {
      id: '39f3b4b0-461b-4640-b70d-b12f4b844bf5',
      type: 'DiamondNode',
      x: 2350,
      y: 650,
      properties: {}
    }, {
      id: 'f5e1902a-c7f8-4848-b2cc-f81526b59f18',
      type: 'DiamondNode',
      x: 2300,
      y: 1100,
      properties: {}
    }, {
      id: 'c38d0048-bfc3-42b2-9143-9e057488a478',
      type: 'DiamondNode',
      x: 2050,
      y: 500,
      properties: {}
    }, {
      id: '95b76f5c-c596-4a24-82f1-ec7e66db7d6a',
      type: 'DiamondNode',
      x: 2400,
      y: 1650,
      properties: {}
    }, {
      id: '411c97aa-44f0-4b12-905b-c7215abb9e3f',
      type: 'SquareNode',
      x: 2400,
      y: 1750,
      properties: {
        text: '8011',
        equipmentId: '159240831179037'
      },
      text: {
        x: 2465,
        y: 1750,
        value: '8011'
      }
    }, {
      id: 'dd276412-6fe3-4c6a-92be-1c7fde602aa5',
      type: 'DiamondNode',
      x: 2800,
      y: 1650,
      properties: {}
    }, {
      id: '82e3dc77-a52b-4ae2-bd9a-6a7611d3fef3',
      type: 'SquareNode',
      x: 2800,
      y: 1750,
      properties: {
        text: '8013',
        equipmentId: '159240831179043'
      },
      text: {
        x: 2865,
        y: 1750,
        value: '8013'
      }
    }, {
      id: '174ecc6b-72a0-455f-8850-4ef51a7bfc5c',
      type: 'DiamondNode',
      x: 1350,
      y: 950,
      properties: {}
    }, {
      id: '6b77037b-676b-45c2-b677-b5e50452e847',
      type: 'SquareNode',
      x: 800,
      y: 1450,
      properties: {
        text: '8105',
        equipmentId: '159240831179015'
      },
      text: {
        x: 865,
        y: 1450,
        value: '8105'
      }
    }, {
      id: 'd560f8d1-f452-486c-85a7-835c0165d386',
      type: 'SquareNode',
      x: 950,
      y: 1450,
      properties: {
        text: '8106',
        equipmentId: '159240831179016'
      },
      text: {
        x: 1015,
        y: 1450,
        value: '8106'
      }
    }, {
      id: '08042f8a-0866-4e55-9c37-596a7152f429',
      type: 'DiamondNode',
      x: 800,
      y: 1350,
      properties: {}
    }, {
      id: '3d6c4f1d-5325-4e1f-85b4-a451741188f3',
      type: 'DiamondNode',
      x: 950,
      y: 1350,
      properties: {}
    }, {
      id: '6cca400e-866b-4a07-bc00-dbd22487edeb',
      type: 'DiamondNode',
      x: -100,
      y: 500,
      properties: {}
    }, {
      id: '39b4011f-10a2-4ef6-95a0-cd375b3aec08',
      type: 'DiamondNode',
      x: 200,
      y: 650,
      properties: {}
    }, {
      id: '6b5f7444-4c1d-43de-a045-aedfee125085',
      type: 'DiamondNode',
      x: 750,
      y: 1100,
      properties: {}
    }, {
      id: '1b8af4f6-1af2-449a-aa37-bf87fbbea9f3',
      type: 'DiamondNode',
      x: 800,
      y: 1650,
      properties: {}
    }, {
      id: '46b57318-f40d-40bf-8580-ad7f07ec9f0f',
      type: 'SquareNode',
      x: 800,
      y: 1750,
      properties: {
        text: '8005',
        equipmentId: '159240831179017'
      },
      text: {
        x: 865,
        y: 1750,
        value: '8005'
      }
    }],
    edges: [{
      id: 'b97d9153-c4b1-4769-9811-ea2214a6f448',
      type: 'polyline',
      sourceNodeId: 'cbeb6fd2-a36a-4a15-b71e-a8522e474bf2',
      targetNodeId: 'ac3b2348-c0cc-406c-a1a6-b726d8ca5dfe',
      startPoint: {
        x: 50,
        y: 925
      },
      endPoint: {
        x: 150,
        y: 1075
      },
      properties: {},
      pointsList: [{
        x: 50,
        y: 925
      }, {
        x: 50,
        y: 950.540891447424
      }, {
        x: 150,
        y: 950.540891447424
      }, {
        x: 150,
        y: 1075
      }]
    }, {
      id: 'c41f122d-3e1c-4baf-8622-8995186f1e40',
      type: 'polyline',
      sourceNodeId: '98738656-979e-4bfd-959e-cd50193969d1',
      targetNodeId: 'ac3b2348-c0cc-406c-a1a6-b726d8ca5dfe',
      startPoint: {
        x: 50,
        y: 1325
      },
      endPoint: {
        x: 150,
        y: 1125
      },
      properties: {},
      pointsList: [{
        x: 50,
        y: 1325
      }, {
        x: 50,
        y: 1299.9653260798345
      }, {
        x: 150,
        y: 1299.9653260798345
      }, {
        x: 150,
        y: 1125
      }]
    }, {
      id: 'ac2e4eea-24bd-4455-8018-eeae86330db4',
      type: 'polyline',
      sourceNodeId: '7e3a98e0-99ba-4659-ba8f-2136a5664136',
      targetNodeId: '98738656-979e-4bfd-959e-cd50193969d1',
      startPoint: {
        x: 200,
        y: 1325
      },
      endPoint: {
        x: 50,
        y: 1325
      },
      properties: {},
      pointsList: [{
        x: 200,
        y: 1325
      }, {
        x: 200,
        y: 1299.953241687123
      }, {
        x: 50,
        y: 1299.953241687123
      }, {
        x: 50,
        y: 1325
      }]
    }, {
      id: '6167e1c9-57bf-477d-b55b-af9545ff7f66',
      type: 'polyline',
      sourceNodeId: 'c8ea4d06-6a4a-4c0b-b6e6-11321b5cfa62',
      targetNodeId: '1bd1939a-b1ac-4bbd-aec9-b64bdf5d3b94',
      startPoint: {
        x: 1575,
        y: 400
      },
      endPoint: {
        x: 1250,
        y: 375
      },
      properties: {},
      pointsList: [{
        x: 1575,
        y: 400
      }, {
        x: 1451.2496857105227,
        y: 400
      }, {
        x: 1451.2496857105227,
        y: 314.95936597763705
      }, {
        x: 1250,
        y: 314.95936597763705
      }, {
        x: 1250,
        y: 375
      }]
    }, {
      id: '93ea4e14-682d-4a7c-9f51-55ff2a42fef6',
      type: 'polyline',
      sourceNodeId: '005057e4-5d9d-4373-a7f2-c7f6136e025d',
      targetNodeId: 'a1522a59-efaa-4263-af7e-51695a8b80b0',
      startPoint: {
        x: 1250,
        y: 1225
      },
      endPoint: {
        x: 1575,
        y: 1200
      },
      properties: {},
      pointsList: [{
        x: 1250,
        y: 1225
      }, {
        x: 1250,
        y: 1263.3948930201368
      }, {
        x: 1449.9028522205203,
        y: 1263.3948930201368
      }, {
        x: 1449.9028522205203,
        y: 1200
      }, {
        x: 1575,
        y: 1200
      }]
    }, {
      id: 'c0f7700c-d219-4202-a3d6-c513a835126d',
      type: 'polyline',
      sourceNodeId: '8f127b91-17c6-4033-8dc6-6bcdc212473a',
      targetNodeId: 'bb1747b5-a4c2-464f-b082-3c9a7a59d0d7',
      startPoint: {
        x: 1825,
        y: 400
      },
      endPoint: {
        x: 2200,
        y: 375
      },
      properties: {},
      pointsList: [{
        x: 1825,
        y: 400
      }, {
        x: 1951.2149193333614,
        y: 400
      }, {
        x: 1951.2149193333614,
        y: 314.47384967313377
      }, {
        x: 2200,
        y: 314.47384967313377
      }, {
        x: 2200,
        y: 375
      }]
    }, {
      id: '1e709fc4-43af-432c-9028-6b29b53d459d',
      type: 'polyline',
      sourceNodeId: 'e03de1ce-5361-4c69-8274-a268705cbd58',
      targetNodeId: 'b3c25ab5-bddf-4f78-955d-572d2af6dcab',
      startPoint: {
        x: 1825,
        y: 1200
      },
      endPoint: {
        x: 2200,
        y: 1225
      },
      properties: {},
      pointsList: [{
        x: 1825,
        y: 1200
      }, {
        x: 1949.3273266276033,
        y: 1200
      }, {
        x: 1949.3273266276033,
        y: 1263.5951863512562
      }, {
        x: 2200,
        y: 1263.5951863512562
      }, {
        x: 2200,
        y: 1225
      }]
    }, {
      id: '22d3dfea-4aa1-4bb1-ad7d-42bf95e591ce',
      type: 'polyline',
      sourceNodeId: '1421a890-b730-4921-939b-f7cb702eb3e5',
      targetNodeId: '8f7067c8-0af8-4ca1-bfd5-1efa28e19f61',
      startPoint: {
        x: 350,
        y: 625
      },
      endPoint: {
        x: 450,
        y: 925
      },
      properties: {},
      pointsList: [{
        x: 350,
        y: 625
      }, {
        x: 350,
        y: 655
      }, {
        x: 450,
        y: 655
      }, {
        x: 450,
        y: 925
      }]
    }, {
      id: 'a8df52a8-4a92-4a8e-ae12-9e5f3340324d',
      type: 'polyline',
      sourceNodeId: '8ae1c8ad-c8ba-45f8-891a-e8041d78ab34',
      targetNodeId: '2e54e1c5-0a7a-4f7f-888c-d6fbf5c8e6f7',
      startPoint: {
        x: 650,
        y: 675
      },
      endPoint: {
        x: 500,
        y: 525
      },
      properties: {},
      pointsList: [{
        x: 650,
        y: 675
      }, {
        x: 650,
        y: 652.496673946332
      }, {
        x: 500,
        y: 652.496673946332
      }, {
        x: 500,
        y: 525
      }]
    }, {
      id: 'ca913b8b-1da5-4414-8029-9c85fa017f82',
      type: 'polyline',
      sourceNodeId: '821a6ce2-4399-440d-b581-d8663e32323b',
      targetNodeId: 'd66f5aab-7e72-44a2-bf4d-2f4518b9f9f8',
      startPoint: {
        x: 950,
        y: 975
      },
      endPoint: {
        x: 800,
        y: 525
      },
      properties: {},
      pointsList: [{
        x: 950,
        y: 975
      }, {
        x: 950,
        y: 652.5099781610037
      }, {
        x: 800,
        y: 652.5099781610037
      }, {
        x: 800,
        y: 525
      }]
    }, {
      id: '20680010-5b62-4465-9f19-5bcd1fc64bdc',
      type: 'polyline',
      sourceNodeId: '58de9779-fad4-462b-967a-db05255810f9',
      targetNodeId: '2803f38e-d738-486d-9ceb-6978ebfe499e',
      startPoint: {
        x: 1250,
        y: 975
      },
      endPoint: {
        x: 1100,
        y: 675
      },
      properties: {},
      pointsList: [{
        x: 1250,
        y: 975
      }, {
        x: 1250,
        y: 951.8020935311395
      }, {
        x: 1100,
        y: 951.8020935311395
      }, {
        x: 1100,
        y: 675
      }]
    }, {
      id: 'a211645d-d4e1-4280-a131-709be50746d4',
      type: 'polyline',
      sourceNodeId: '503678de-0f86-4402-b536-4b8fa7e52fe7',
      targetNodeId: '174ecc6b-72a0-455f-8850-4ef51a7bfc5c',
      startPoint: {
        x: 1250,
        y: 625
      },
      endPoint: {
        x: 1350,
        y: 925
      },
      properties: {},
      pointsList: [{
        x: 1250,
        y: 625
      }, {
        x: 1250,
        y: 655
      }, {
        x: 1350,
        y: 655
      }, {
        x: 1350,
        y: 925
      }]
    }, {
      id: 'f61f576f-9d3e-423b-99d2-6712da9ff69f',
      type: 'polyline',
      sourceNodeId: '9f5ed12f-9b06-4348-97d4-a3a3dac1679b',
      targetNodeId: '8f7067c8-0af8-4ca1-bfd5-1efa28e19f61',
      startPoint: {
        x: 400,
        y: 1325
      },
      endPoint: {
        x: 450,
        y: 975
      },
      properties: {},
      pointsList: [{
        x: 400,
        y: 1325
      }, {
        x: 400,
        y: 1299.6460576850682
      }, {
        x: 450,
        y: 1299.6460576850682
      }, {
        x: 450,
        y: 975
      }]
    }, {
      id: '310e2572-f45f-4b1c-9ff6-d270a70bb855',
      type: 'polyline',
      sourceNodeId: 'adaeae53-f93c-4115-8a48-67bb5c92e347',
      targetNodeId: '9f5ed12f-9b06-4348-97d4-a3a3dac1679b',
      startPoint: {
        x: 550,
        y: 1325
      },
      endPoint: {
        x: 400,
        y: 1325
      },
      properties: {},
      pointsList: [{
        x: 550,
        y: 1325
      }, {
        x: 550,
        y: 1299.5347290207596
      }, {
        x: 400,
        y: 1299.5347290207596
      }, {
        x: 400,
        y: 1325
      }]
    }, {
      id: '3f8764ea-76a1-4745-9f31-1b36dbc73454',
      type: 'polyline',
      sourceNodeId: 'f02ac32f-3074-4094-8469-24726c029562',
      targetNodeId: '6cca400e-866b-4a07-bc00-dbd22487edeb',
      startPoint: {
        x: 50,
        y: 675
      },
      endPoint: {
        x: -100,
        y: 525
      },
      properties: {},
      pointsList: [{
        x: 50,
        y: 675
      }, {
        x: 50,
        y: 652.4966739463322
      }, {
        x: -100,
        y: 652.4966739463322
      }, {
        x: -100,
        y: 525
      }]
    }, {
      id: 'c76e20ac-cb58-426d-85c1-9dfc3ad5d9df',
      type: 'polyline',
      sourceNodeId: 'ad382f70-223a-48d7-8e65-08fec9cd74a7',
      targetNodeId: '39b4011f-10a2-4ef6-95a0-cd375b3aec08',
      startPoint: {
        x: 350,
        y: 975
      },
      endPoint: {
        x: 200,
        y: 675
      },
      properties: {},
      pointsList: [{
        x: 350,
        y: 975
      }, {
        x: 350,
        y: 954.0694580415193
      }, {
        x: 200,
        y: 954.0694580415193
      }, {
        x: 200,
        y: 675
      }]
    }, {
      id: '9b6ff56d-ee09-4acd-aa1c-48efa56dfd80',
      type: 'polyline',
      sourceNodeId: '50be17e3-cb83-4a13-84ad-dcbd3d6cf831',
      targetNodeId: '3f92366a-a8e5-4e24-8fec-9804c0043d6a',
      startPoint: {
        x: 50,
        y: 425
      },
      endPoint: {
        x: 50,
        y: 1175
      },
      properties: {},
      pointsList: [{
        x: 50,
        y: 425
      }, {
        x: 50,
        y: 1175
      }]
    }, {
      id: 'e7438269-befa-4f34-8585-b6926eb9b401',
      type: 'polyline',
      sourceNodeId: '72879a5d-3bc4-41c8-9b55-3f19931e65f1',
      targetNodeId: '67b01545-7e3b-44be-b6dd-6b7a63ed6824',
      startPoint: {
        x: 350,
        y: 425
      },
      endPoint: {
        x: 350,
        y: 1175
      },
      properties: {},
      pointsList: [{
        x: 350,
        y: 425
      }, {
        x: 350,
        y: 1175
      }]
    }, {
      id: '2cb4dbb0-3b1a-454f-abf0-3865826dfc3d',
      type: 'polyline',
      sourceNodeId: '2aadc8b2-c50b-4216-8d49-de1a08da36fa',
      targetNodeId: 'c3c1aa8d-d2e8-4f3c-9929-f4599538e737',
      startPoint: {
        x: 650,
        y: 425
      },
      endPoint: {
        x: 650,
        y: 1175
      },
      properties: {},
      pointsList: [{
        x: 650,
        y: 425
      }, {
        x: 650,
        y: 1175
      }]
    }, {
      id: '2204753e-2512-4be5-87c1-bd7d2d264e2d',
      type: 'polyline',
      sourceNodeId: '543a2677-566d-4306-ba68-37ab91b0da4f',
      targetNodeId: '0abfeadb-0b04-4992-b69f-6121b4234cf4',
      startPoint: {
        x: 950,
        y: 425
      },
      endPoint: {
        x: 950,
        y: 1175
      },
      properties: {},
      pointsList: [{
        x: 950,
        y: 425
      }, {
        x: 950,
        y: 1175
      }]
    }, {
      id: '5d959253-2600-4fb7-a0dc-23e98aca1d2b',
      type: 'polyline',
      sourceNodeId: '1bd1939a-b1ac-4bbd-aec9-b64bdf5d3b94',
      targetNodeId: '005057e4-5d9d-4373-a7f2-c7f6136e025d',
      startPoint: {
        x: 1250,
        y: 425
      },
      endPoint: {
        x: 1250,
        y: 1175
      },
      properties: {},
      pointsList: [{
        x: 1250,
        y: 425
      }, {
        x: 1250,
        y: 1175
      }]
    }, {
      id: 'bf75592e-dc43-4d03-835c-ba9ffc5e41bb',
      type: 'polyline',
      sourceNodeId: '98738656-979e-4bfd-959e-cd50193969d1',
      targetNodeId: '20194b1b-6033-4278-b984-a5fa250bc7e5',
      startPoint: {
        x: 50,
        y: 1375
      },
      endPoint: {
        x: 50,
        y: 1885
      },
      properties: {},
      pointsList: [{
        x: 50,
        y: 1375
      }, {
        x: 50,
        y: 1885
      }]
    }, {
      id: 'f7df8175-13f1-4c60-912c-647a2bb45cda',
      type: 'polyline',
      sourceNodeId: '7e3a98e0-99ba-4659-ba8f-2136a5664136',
      targetNodeId: '307ace3d-b6ca-4955-b3bf-5d6e79e90996',
      startPoint: {
        x: 200,
        y: 1375
      },
      endPoint: {
        x: 200,
        y: 1885
      },
      properties: {},
      pointsList: [{
        x: 200,
        y: 1375
      }, {
        x: 200,
        y: 1885
      }]
    }, {
      id: '8c53db6b-430d-4472-9398-5019327bbbe4',
      type: 'polyline',
      sourceNodeId: '9f5ed12f-9b06-4348-97d4-a3a3dac1679b',
      targetNodeId: '03107c76-ee8d-47e6-83cb-fe404545b07a',
      startPoint: {
        x: 400,
        y: 1375
      },
      endPoint: {
        x: 400,
        y: 1885
      },
      properties: {},
      pointsList: [{
        x: 400,
        y: 1375
      }, {
        x: 400,
        y: 1885
      }]
    }, {
      id: '0fe9e689-c80e-43b9-9dcf-229c7c03730e',
      type: 'polyline',
      sourceNodeId: 'adaeae53-f93c-4115-8a48-67bb5c92e347',
      targetNodeId: '6f7b7998-938c-4990-a255-7ca2f80bff51',
      startPoint: {
        x: 550,
        y: 1375
      },
      endPoint: {
        x: 550,
        y: 1885
      },
      properties: {},
      pointsList: [{
        x: 550,
        y: 1375
      }, {
        x: 550,
        y: 1885
      }]
    }, {
      id: 'cf98b848-066a-4bde-b202-b5685aa6dddc',
      type: 'polyline',
      sourceNodeId: '3d6c4f1d-5325-4e1f-85b4-a451741188f3',
      targetNodeId: 'c6177f2d-35d5-4e0d-bf79-602a1c8210b2',
      startPoint: {
        x: 950,
        y: 1375
      },
      endPoint: {
        x: 950,
        y: 1885
      },
      properties: {},
      pointsList: [{
        x: 950,
        y: 1375
      }, {
        x: 950,
        y: 1885
      }]
    }, {
      id: 'f02c5f79-7a2d-4a7e-aa4c-35df52182151',
      type: 'polyline',
      sourceNodeId: 'af94d4a7-f35a-4172-9cb7-b5fb4655aa4f',
      targetNodeId: '1ab09c07-3bda-439d-8b69-6260d6246b14',
      startPoint: {
        x: 1250,
        y: 1375
      },
      endPoint: {
        x: 1250,
        y: 1885
      },
      properties: {},
      pointsList: [{
        x: 1250,
        y: 1375
      }, {
        x: 1250,
        y: 1885
      }]
    }, {
      id: '68d9a2cf-f0af-4436-b87e-3c855e87c996',
      type: 'polyline',
      sourceNodeId: '11179814-3818-42ad-8ff6-470580988aac',
      targetNodeId: '961933b9-b64c-4351-b86b-e551a838046d',
      startPoint: {
        x: 1400,
        y: 1375
      },
      endPoint: {
        x: 1400,
        y: 1885
      },
      properties: {},
      pointsList: [{
        x: 1400,
        y: 1375
      }, {
        x: 1400,
        y: 1885
      }]
    }, {
      id: 'eb9fc4b8-be22-4546-a5b0-b98eb1f93156',
      type: 'polyline',
      sourceNodeId: 'bb1747b5-a4c2-464f-b082-3c9a7a59d0d7',
      targetNodeId: 'b3c25ab5-bddf-4f78-955d-572d2af6dcab',
      startPoint: {
        x: 2200,
        y: 425
      },
      endPoint: {
        x: 2200,
        y: 1175
      },
      properties: {},
      pointsList: [{
        x: 2200,
        y: 425
      }, {
        x: 2200,
        y: 1175
      }]
    }, {
      id: '4d3ce786-e1b6-43c4-9acd-493361c81adc',
      type: 'polyline',
      sourceNodeId: 'b7b8bdcb-c144-45ba-9aea-21ea32264076',
      targetNodeId: 'c40ceecc-51e5-4cf2-b27a-d6c81e47e3a7',
      startPoint: {
        x: 2500,
        y: 425
      },
      endPoint: {
        x: 2500,
        y: 1175
      },
      properties: {},
      pointsList: [{
        x: 2500,
        y: 425
      }, {
        x: 2500,
        y: 1175
      }]
    }, {
      id: '1c4514d8-44d0-41a6-8b13-00eb30c9d2be',
      type: 'polyline',
      sourceNodeId: '580f219c-33af-425d-88cf-21d71f9ab7db',
      targetNodeId: '81aa0988-e4f5-4e8e-a68b-874532193aab',
      startPoint: {
        x: 2800,
        y: 425
      },
      endPoint: {
        x: 2800,
        y: 1175
      },
      properties: {},
      pointsList: [{
        x: 2800,
        y: 425
      }, {
        x: 2800,
        y: 1175
      }]
    }, {
      id: '2d8ab0d9-0bde-4dd5-850d-9c9824707d67',
      type: 'polyline',
      sourceNodeId: '34d56b94-e4ec-4a83-a20c-dc9a133aa683',
      targetNodeId: '09fe1a16-4d83-4da6-a259-0b44a258895b',
      startPoint: {
        x: 2000,
        y: 1375
      },
      endPoint: {
        x: 2000,
        y: 1885
      },
      properties: {},
      pointsList: [{
        x: 2000,
        y: 1375
      }, {
        x: 2000,
        y: 1885
      }]
    }, {
      id: '6068b4f5-3a70-4a17-8d3d-3a6b5921b6aa',
      type: 'polyline',
      sourceNodeId: 'a201f0e8-63fb-4b11-9980-c643a7a1a093',
      targetNodeId: '85f1eae2-8698-49f1-ba68-679356eaa917',
      startPoint: {
        x: 2150,
        y: 1375
      },
      endPoint: {
        x: 2150,
        y: 1885
      },
      properties: {},
      pointsList: [{
        x: 2150,
        y: 1375
      }, {
        x: 2150,
        y: 1885
      }]
    }, {
      id: 'a521f91b-68a7-4122-acfe-d44d331492f8',
      type: 'polyline',
      sourceNodeId: 'aa42b2ad-167a-4250-b22f-85e74eb64467',
      targetNodeId: '86c735cf-5cde-44a0-9440-3e3a7579c3fa',
      startPoint: {
        x: 2400,
        y: 1375
      },
      endPoint: {
        x: 2400,
        y: 1885
      },
      properties: {},
      pointsList: [{
        x: 2400,
        y: 1375
      }, {
        x: 2400,
        y: 1885
      }]
    }, {
      id: '493d4010-0408-4510-8dcf-1885af18c0d5',
      type: 'polyline',
      sourceNodeId: 'b60005e0-1e77-4219-8f17-29f1567c516f',
      targetNodeId: 'd4e3324b-1503-48a1-afdb-0fc3b6a6596b',
      startPoint: {
        x: 2550,
        y: 1375
      },
      endPoint: {
        x: 2550,
        y: 1885
      },
      properties: {},
      pointsList: [{
        x: 2550,
        y: 1375
      }, {
        x: 2550,
        y: 1885
      }]
    }, {
      id: '28099415-7f33-489c-a718-5eba2683f4b6',
      type: 'polyline',
      sourceNodeId: '069a44f5-6078-49b1-abfc-bab0c4690ea3',
      targetNodeId: 'f726a3ea-3b90-4a7f-aeac-dee134167cb6',
      startPoint: {
        x: 2800,
        y: 1375
      },
      endPoint: {
        x: 2800,
        y: 1885
      },
      properties: {},
      pointsList: [{
        x: 2800,
        y: 1375
      }, {
        x: 2800,
        y: 1885
      }]
    }, {
      id: '6ad13083-801c-4af6-b845-24463ebac5ba',
      type: 'polyline',
      sourceNodeId: 'f7caaa40-092b-4b8b-9b50-36a140b148ef',
      targetNodeId: '57d215db-cb75-48d5-a8e8-7129df228060',
      startPoint: {
        x: 2950,
        y: 1375
      },
      endPoint: {
        x: 2950,
        y: 1885
      },
      properties: {},
      pointsList: [{
        x: 2950,
        y: 1375
      }, {
        x: 2950,
        y: 1885
      }]
    }, {
      id: 'ff57dabe-7374-4437-8f56-70c62258947b',
      type: 'polyline',
      sourceNodeId: 'a1522a59-efaa-4263-af7e-51695a8b80b0',
      targetNodeId: 'e03de1ce-5361-4c69-8274-a268705cbd58',
      startPoint: {
        x: 1625,
        y: 1200
      },
      endPoint: {
        x: 1775,
        y: 1200
      },
      properties: {},
      pointsList: [{
        x: 1625,
        y: 1200
      }, {
        x: 1775,
        y: 1200
      }]
    }, {
      id: 'a0e12855-1cd5-40ed-9d3b-be10ba9c6da9',
      type: 'polyline',
      sourceNodeId: 'c8ea4d06-6a4a-4c0b-b6e6-11321b5cfa62',
      targetNodeId: '8f127b91-17c6-4033-8dc6-6bcdc212473a',
      startPoint: {
        x: 1625,
        y: 400
      },
      endPoint: {
        x: 1775,
        y: 400
      },
      properties: {},
      pointsList: [{
        x: 1625,
        y: 400
      }, {
        x: 1775,
        y: 400
      }]
    }, {
      id: '9f7502a4-0406-48f1-bb46-222b2f6c62a6',
      type: 'polyline',
      sourceNodeId: '856c5b8c-66f1-42c3-b358-c776b4afc951',
      targetNodeId: 'c38d0048-bfc3-42b2-9143-9e057488a478',
      startPoint: {
        x: 2200,
        y: 675
      },
      endPoint: {
        x: 2050,
        y: 525
      },
      properties: {},
      pointsList: [{
        x: 2200,
        y: 675
      }, {
        x: 2200,
        y: 654.7640384567117
      }, {
        x: 2050,
        y: 654.7640384567117
      }, {
        x: 2050,
        y: 525
      }]
    }, {
      id: 'aafd791e-5c3b-45d8-af71-dd1983c4183f',
      type: 'polyline',
      sourceNodeId: 'f5e1902a-c7f8-4848-b2cc-f81526b59f18',
      targetNodeId: '662b7e89-1ada-4633-832f-694b486490fe',
      startPoint: {
        x: 2300,
        y: 1075
      },
      endPoint: {
        x: 2200,
        y: 925
      },
      properties: {},
      pointsList: [{
        x: 2300,
        y: 1075
      }, {
        x: 2300,
        y: 955
      }, {
        x: 2200,
        y: 955
      }, {
        x: 2200,
        y: 925
      }]
    }, {
      id: 'e3bf6430-42b8-4d50-b04a-bc6c850f6247',
      type: 'polyline',
      sourceNodeId: 'a201f0e8-63fb-4b11-9980-c643a7a1a093',
      targetNodeId: 'f5e1902a-c7f8-4848-b2cc-f81526b59f18',
      startPoint: {
        x: 2150,
        y: 1325
      },
      endPoint: {
        x: 2300,
        y: 1125
      },
      properties: {},
      pointsList: [{
        x: 2150,
        y: 1325
      }, {
        x: 2150,
        y: 1300.1113286643085
      }, {
        x: 2300,
        y: 1300.1113286643085
      }, {
        x: 2300,
        y: 1125
      }]
    }, {
      id: '7d1c8365-d547-4cc1-a5b3-85aca2ac53c0',
      type: 'polyline',
      sourceNodeId: '34d56b94-e4ec-4a83-a20c-dc9a133aa683',
      targetNodeId: 'a201f0e8-63fb-4b11-9980-c643a7a1a093',
      startPoint: {
        x: 2000,
        y: 1325
      },
      endPoint: {
        x: 2150,
        y: 1325
      },
      properties: {},
      pointsList: [{
        x: 2000,
        y: 1325
      }, {
        x: 2000,
        y: 1299.5347290207596
      }, {
        x: 2150,
        y: 1299.5347290207596
      }, {
        x: 2150,
        y: 1325
      }]
    }, {
      id: '198e8e75-4ca6-4ac4-a239-12e5cc7e509d',
      type: 'polyline',
      sourceNodeId: '39f3b4b0-461b-4640-b70d-b12f4b844bf5',
      targetNodeId: '845ca97d-645e-4d9c-bb6a-6eb09c02a211',
      startPoint: {
        x: 2350,
        y: 675
      },
      endPoint: {
        x: 2500,
        y: 975
      },
      properties: {},
      pointsList: [{
        x: 2350,
        y: 675
      }, {
        x: 2350,
        y: 954.4100961417796
      }, {
        x: 2500,
        y: 954.4100961417796
      }, {
        x: 2500,
        y: 975
      }]
    }, {
      id: '69bb41ea-b6a1-461c-b0b5-859e75b6f906',
      type: 'polyline',
      sourceNodeId: 'f22283b5-77ce-446f-90fc-962af4eade96',
      targetNodeId: '6b5aac9d-e986-4929-b878-73a3acc167e9',
      startPoint: {
        x: 2500,
        y: 625
      },
      endPoint: {
        x: 2600,
        y: 925
      },
      properties: {},
      pointsList: [{
        x: 2500,
        y: 625
      }, {
        x: 2500,
        y: 655
      }, {
        x: 2600,
        y: 655
      }, {
        x: 2600,
        y: 925
      }]
    }, {
      id: '937afae9-31eb-4602-98a6-76c7e3397059',
      type: 'polyline',
      sourceNodeId: 'aa42b2ad-167a-4250-b22f-85e74eb64467',
      targetNodeId: 'b60005e0-1e77-4219-8f17-29f1567c516f',
      startPoint: {
        x: 2400,
        y: 1325
      },
      endPoint: {
        x: 2550,
        y: 1325
      },
      properties: {},
      pointsList: [{
        x: 2400,
        y: 1325
      }, {
        x: 2400,
        y: 1299.5347290207596
      }, {
        x: 2550,
        y: 1299.5347290207596
      }, {
        x: 2550,
        y: 1325
      }]
    }, {
      id: 'a5321e9c-ec07-4950-8bd5-63053486d083',
      type: 'polyline',
      sourceNodeId: 'b60005e0-1e77-4219-8f17-29f1567c516f',
      targetNodeId: '6b5aac9d-e986-4929-b878-73a3acc167e9',
      startPoint: {
        x: 2550,
        y: 1325
      },
      endPoint: {
        x: 2600,
        y: 975
      },
      properties: {},
      pointsList: [{
        x: 2550,
        y: 1325
      }, {
        x: 2550,
        y: 1299.6460576850682
      }, {
        x: 2600,
        y: 1299.6460576850682
      }, {
        x: 2600,
        y: 975
      }]
    }, {
      id: 'f3dc8832-742d-4e9b-b08f-b57f83146666',
      type: 'polyline',
      sourceNodeId: '2159e68f-6834-4601-8cb9-4b2d908988fa',
      targetNodeId: 'aa1a4092-15fe-461f-ad76-b0e468b45509',
      startPoint: {
        x: 2650,
        y: 525
      },
      endPoint: {
        x: 2800,
        y: 675
      },
      properties: {},
      pointsList: [{
        x: 2650,
        y: 525
      }, {
        x: 2650,
        y: 654.0694580415193
      }, {
        x: 2800,
        y: 654.0694580415193
      }, {
        x: 2800,
        y: 675
      }]
    }, {
      id: '4a1d9b5f-3f69-4c85-9aea-7539dca72a35',
      type: 'polyline',
      sourceNodeId: 'd68a6a0b-dbc0-488d-b75e-d17f4ed325ca',
      targetNodeId: '70d2277c-ec05-4582-b802-84e3f10b809e',
      startPoint: {
        x: 2800,
        y: 925
      },
      endPoint: {
        x: 2900,
        y: 1075
      },
      properties: {},
      pointsList: [{
        x: 2800,
        y: 925
      }, {
        x: 2800,
        y: 954.3054195848072
      }, {
        x: 2900,
        y: 954.3054195848072
      }, {
        x: 2900,
        y: 1075
      }]
    }, {
      id: 'd60c080c-a9d9-4e45-b493-5f03e08a8013',
      type: 'polyline',
      sourceNodeId: '069a44f5-6078-49b1-abfc-bab0c4690ea3',
      targetNodeId: '70d2277c-ec05-4582-b802-84e3f10b809e',
      startPoint: {
        x: 2800,
        y: 1325
      },
      endPoint: {
        x: 2900,
        y: 1125
      },
      properties: {},
      pointsList: [{
        x: 2800,
        y: 1325
      }, {
        x: 2800,
        y: 1295
      }, {
        x: 2900,
        y: 1295
      }, {
        x: 2900,
        y: 1125
      }]
    }, {
      id: 'e05f51db-c84e-4847-95a8-0f918b5a558e',
      type: 'polyline',
      sourceNodeId: 'f7caaa40-092b-4b8b-9b50-36a140b148ef',
      targetNodeId: '069a44f5-6078-49b1-abfc-bab0c4690ea3',
      startPoint: {
        x: 2950,
        y: 1325
      },
      endPoint: {
        x: 2800,
        y: 1325
      },
      properties: {},
      pointsList: [{
        x: 2950,
        y: 1325
      }, {
        x: 2950,
        y: 1295
      }, {
        x: 2800,
        y: 1295
      }, {
        x: 2800,
        y: 1325
      }]
    }, {
      id: 'f01703a3-9862-4bc1-a91c-ad4f8fdf2bcd',
      type: 'polyline',
      sourceNodeId: '6b5f7444-4c1d-43de-a045-aedfee125085',
      targetNodeId: 'd9d0c076-f1b7-44c0-9e9a-34c67db52cfe',
      startPoint: {
        x: 750,
        y: 1075
      },
      endPoint: {
        x: 650,
        y: 925
      },
      properties: {},
      pointsList: [{
        x: 750,
        y: 1075
      }, {
        x: 750,
        y: 955
      }, {
        x: 650,
        y: 955
      }, {
        x: 650,
        y: 925
      }]
    }, {
      id: '66611327-0709-4f3a-815e-40c3edbe218b',
      type: 'polyline',
      sourceNodeId: '08042f8a-0866-4e55-9c37-596a7152f429',
      targetNodeId: '6b5f7444-4c1d-43de-a045-aedfee125085',
      startPoint: {
        x: 800,
        y: 1325
      },
      endPoint: {
        x: 750,
        y: 1125
      },
      properties: {},
      pointsList: [{
        x: 800,
        y: 1325
      }, {
        x: 800,
        y: 1304.3577578632935
      }, {
        x: 750,
        y: 1304.3577578632935
      }, {
        x: 750,
        y: 1125
      }]
    }, {
      id: '2ffdf687-92c2-4afa-83ce-d355ba6a7e7b',
      type: 'polyline',
      sourceNodeId: '3d6c4f1d-5325-4e1f-85b4-a451741188f3',
      targetNodeId: '08042f8a-0866-4e55-9c37-596a7152f429',
      startPoint: {
        x: 950,
        y: 1325
      },
      endPoint: {
        x: 800,
        y: 1325
      },
      properties: {},
      pointsList: [{
        x: 950,
        y: 1325
      }, {
        x: 950,
        y: 1304.0694580415193
      }, {
        x: 800,
        y: 1304.0694580415193
      }, {
        x: 800,
        y: 1325
      }]
    }, {
      id: '9d5e4c84-a022-4986-aa77-e4a6ed42cf17',
      type: 'polyline',
      sourceNodeId: 'af94d4a7-f35a-4172-9cb7-b5fb4655aa4f',
      targetNodeId: '174ecc6b-72a0-455f-8850-4ef51a7bfc5c',
      startPoint: {
        x: 1250,
        y: 1325
      },
      endPoint: {
        x: 1350,
        y: 975
      },
      properties: {},
      pointsList: [{
        x: 1250,
        y: 1325
      }, {
        x: 1250,
        y: 1295
      }, {
        x: 1350,
        y: 1295
      }, {
        x: 1350,
        y: 975
      }]
    }, {
      id: 'd8aaa9b4-d562-4a0d-82f7-fd2acb958822',
      type: 'polyline',
      sourceNodeId: '11179814-3818-42ad-8ff6-470580988aac',
      targetNodeId: 'af94d4a7-f35a-4172-9cb7-b5fb4655aa4f',
      startPoint: {
        x: 1400,
        y: 1325
      },
      endPoint: {
        x: 1250,
        y: 1325
      },
      properties: {},
      pointsList: [{
        x: 1400,
        y: 1325
      }, {
        x: 1400,
        y: 1295
      }, {
        x: 1250,
        y: 1295
      }, {
        x: 1250,
        y: 1325
      }]
    }, {
      id: 'afc44ebc-4169-4e84-a583-027cf337c9a7',
      type: 'polyline',
      sourceNodeId: '08042f8a-0866-4e55-9c37-596a7152f429',
      targetNodeId: 'b6e5ed71-83e9-46c1-a0a7-4d2306e45f60',
      startPoint: {
        x: 800,
        y: 1375
      },
      endPoint: {
        x: 800,
        y: 1885
      },
      properties: {},
      pointsList: [{
        x: 800,
        y: 1375
      }, {
        x: 800,
        y: 1885
      }]
    }, {
      id: '4e15746d-2914-494b-b874-a989a1296faf',
      type: 'polyline',
      sourceNodeId: '0abfeadb-0b04-4992-b69f-6121b4234cf4',
      targetNodeId: '005057e4-5d9d-4373-a7f2-c7f6136e025d',
      startPoint: {
        x: 950,
        y: 1225
      },
      endPoint: {
        x: 1250,
        y: 1225
      },
      properties: {},
      pointsList: [{
        x: 950,
        y: 1225
      }, {
        x: 950,
        y: 1263.2339299912817
      }, {
        x: 1250,
        y: 1263.2339299912817
      }, {
        x: 1250,
        y: 1225
      }]
    }, {
      id: '21cb70ca-d882-41e6-bb68-c5f3a10c2ec3',
      type: 'polyline',
      sourceNodeId: '67b01545-7e3b-44be-b6dd-6b7a63ed6824',
      targetNodeId: 'c3c1aa8d-d2e8-4f3c-9929-f4599538e737',
      startPoint: {
        x: 350,
        y: 1225
      },
      endPoint: {
        x: 650,
        y: 1225
      },
      properties: {},
      pointsList: [{
        x: 350,
        y: 1225
      }, {
        x: 350,
        y: 1263.3885692898966
      }, {
        x: 650,
        y: 1263.3885692898966
      }, {
        x: 650,
        y: 1225
      }]
    }, {
      id: '5ba456cb-febd-4101-8374-636f6c69dcc7',
      type: 'polyline',
      sourceNodeId: '72879a5d-3bc4-41c8-9b55-3f19931e65f1',
      targetNodeId: '2aadc8b2-c50b-4216-8d49-de1a08da36fa',
      startPoint: {
        x: 350,
        y: 375
      },
      endPoint: {
        x: 650,
        y: 375
      },
      properties: {},
      pointsList: [{
        x: 350,
        y: 375
      }, {
        x: 350,
        y: 315.02599461671775
      }, {
        x: 650,
        y: 315.02599461671775
      }, {
        x: 650,
        y: 375
      }]
    }, {
      id: '6c0ea87d-fc88-4d60-b92d-d16b909e531f',
      type: 'polyline',
      sourceNodeId: '2aadc8b2-c50b-4216-8d49-de1a08da36fa',
      targetNodeId: '543a2677-566d-4306-ba68-37ab91b0da4f',
      startPoint: {
        x: 650,
        y: 375
      },
      endPoint: {
        x: 950,
        y: 375
      },
      properties: {},
      pointsList: [{
        x: 650,
        y: 375
      }, {
        x: 650,
        y: 315.02599461671775
      }, {
        x: 950,
        y: 315.02599461671775
      }, {
        x: 950,
        y: 375
      }]
    }, {
      id: '36242df3-f1b0-48c3-a718-4f50fdd5a8a5',
      type: 'polyline',
      sourceNodeId: 'c40ceecc-51e5-4cf2-b27a-d6c81e47e3a7',
      targetNodeId: '81aa0988-e4f5-4e8e-a68b-874532193aab',
      startPoint: {
        x: 2500,
        y: 1225
      },
      endPoint: {
        x: 2800,
        y: 1225
      },
      properties: {},
      pointsList: [{
        x: 2500,
        y: 1225
      }, {
        x: 2500,
        y: 1263.9289580346422
      }, {
        x: 2800,
        y: 1263.9289580346422
      }, {
        x: 2800,
        y: 1225
      }]
    }, {
      id: '98d3b5a4-af96-4122-b22d-7a7608791ec3',
      type: 'polyline',
      sourceNodeId: 'b7b8bdcb-c144-45ba-9aea-21ea32264076',
      targetNodeId: '580f219c-33af-425d-88cf-21d71f9ab7db',
      startPoint: {
        x: 2500,
        y: 375
      },
      endPoint: {
        x: 2800,
        y: 375
      },
      properties: {},
      pointsList: [{
        x: 2500,
        y: 375
      }, {
        x: 2500,
        y: 316.3239285165652
      }, {
        x: 2800,
        y: 316.3239285165652
      }, {
        x: 2800,
        y: 375
      }]
    }, {
      id: 'b5997cf0-22a1-4b7f-b89b-48235f9558f4',
      type: 'polyline',
      sourceNodeId: '50be17e3-cb83-4a13-84ad-dcbd3d6cf831',
      targetNodeId: '72879a5d-3bc4-41c8-9b55-3f19931e65f1',
      startPoint: {
        x: 50,
        y: 375
      },
      endPoint: {
        x: 350,
        y: 375
      },
      properties: {},
      pointsList: [{
        x: 50,
        y: 375
      }, {
        x: 50,
        y: 315.3230702151802
      }, {
        x: 350,
        y: 315.3230702151802
      }, {
        x: 350,
        y: 375
      }]
    }, {
      id: '27da2744-4395-4c28-b381-984fd72bbdca',
      type: 'polyline',
      sourceNodeId: '543a2677-566d-4306-ba68-37ab91b0da4f',
      targetNodeId: '1bd1939a-b1ac-4bbd-aec9-b64bdf5d3b94',
      startPoint: {
        x: 950,
        y: 375
      },
      endPoint: {
        x: 1250,
        y: 375
      },
      properties: {},
      pointsList: [{
        x: 950,
        y: 375
      }, {
        x: 950,
        y: 315.3230702151802
      }, {
        x: 1250,
        y: 315.3230702151802
      }, {
        x: 1250,
        y: 375
      }]
    }, {
      id: 'c9a21857-9ee5-4aff-8858-67f759200f07',
      type: 'polyline',
      sourceNodeId: 'bb1747b5-a4c2-464f-b082-3c9a7a59d0d7',
      targetNodeId: 'b7b8bdcb-c144-45ba-9aea-21ea32264076',
      startPoint: {
        x: 2200,
        y: 375
      },
      endPoint: {
        x: 2500,
        y: 375
      },
      properties: {},
      pointsList: [{
        x: 2200,
        y: 375
      }, {
        x: 2200,
        y: 315.67780473783114
      }, {
        x: 2500,
        y: 315.67780473783114
      }, {
        x: 2500,
        y: 375
      }]
    }, {
      id: '57894e02-22af-49b8-b1ba-0b2724351e5c',
      type: 'polyline',
      sourceNodeId: 'b3c25ab5-bddf-4f78-955d-572d2af6dcab',
      targetNodeId: 'c40ceecc-51e5-4cf2-b27a-d6c81e47e3a7',
      startPoint: {
        x: 2200,
        y: 1225
      },
      endPoint: {
        x: 2500,
        y: 1225
      },
      properties: {},
      pointsList: [{
        x: 2200,
        y: 1225
      }, {
        x: 2200,
        y: 1263.9172143400413
      }, {
        x: 2500,
        y: 1263.9172143400413
      }, {
        x: 2500,
        y: 1225
      }]
    }, {
      id: 'c8d526c9-b56b-4fa4-bf16-ee62477a1528',
      type: 'polyline',
      sourceNodeId: '3f92366a-a8e5-4e24-8fec-9804c0043d6a',
      targetNodeId: '67b01545-7e3b-44be-b6dd-6b7a63ed6824',
      startPoint: {
        x: 50,
        y: 1225
      },
      endPoint: {
        x: 350,
        y: 1225
      },
      properties: {},
      pointsList: [{
        x: 50,
        y: 1225
      }, {
        x: 50,
        y: 1263.9172143400413
      }, {
        x: 350,
        y: 1263.9172143400413
      }, {
        x: 350,
        y: 1225
      }]
    }, {
      id: '4ac65ce5-ca22-4b20-8ae0-8c0a8444bf15',
      type: 'polyline',
      sourceNodeId: 'c3c1aa8d-d2e8-4f3c-9929-f4599538e737',
      targetNodeId: '0abfeadb-0b04-4992-b69f-6121b4234cf4',
      startPoint: {
        x: 650,
        y: 1225
      },
      endPoint: {
        x: 950,
        y: 1225
      },
      properties: {},
      pointsList: [{
        x: 650,
        y: 1225
      }, {
        x: 650,
        y: 1265.0806100665272
      }, {
        x: 950,
        y: 1265.0806100665272
      }, {
        x: 950,
        y: 1225
      }]
    }, {
      id: '134b9c8e-10d2-4261-b684-4c09638cac8d',
      type: 'polyline',
      sourceNodeId: '6cca400e-866b-4a07-bc00-dbd22487edeb',
      targetNodeId: '3f91a569-2908-47ce-86e1-6de4111d88f3',
      startPoint: {
        x: -100,
        y: 475
      },
      endPoint: {
        x: -100,
        y: 285
      },
      properties: {},
      pointsList: [{
        x: -100,
        y: 475
      }, {
        x: -100,
        y: 285
      }]
    }, {
      id: 'e7db767a-08ba-43d3-bd59-c761b0f6f341',
      type: 'polyline',
      sourceNodeId: '39b4011f-10a2-4ef6-95a0-cd375b3aec08',
      targetNodeId: '255e9803-213b-4930-ab72-8a1e9d353010',
      startPoint: {
        x: 200,
        y: 625
      },
      endPoint: {
        x: 200,
        y: 285
      },
      properties: {},
      pointsList: [{
        x: 200,
        y: 625
      }, {
        x: 200,
        y: 285
      }]
    }, {
      id: '3a7c846c-93d9-443f-ac91-c167ed09cb51',
      type: 'polyline',
      sourceNodeId: '2e54e1c5-0a7a-4f7f-888c-d6fbf5c8e6f7',
      targetNodeId: '6f2ab891-5328-4486-8bae-452d888c93e9',
      startPoint: {
        x: 500,
        y: 475
      },
      endPoint: {
        x: 500,
        y: 285
      },
      properties: {},
      pointsList: [{
        x: 500,
        y: 475
      }, {
        x: 500,
        y: 285
      }]
    }, {
      id: 'c9b588ce-320c-45fc-9e46-1618ed0136b6',
      type: 'polyline',
      sourceNodeId: 'd66f5aab-7e72-44a2-bf4d-2f4518b9f9f8',
      targetNodeId: '397487f1-ffbd-4f7d-942e-7ed077c60217',
      startPoint: {
        x: 800,
        y: 475
      },
      endPoint: {
        x: 800,
        y: 285
      },
      properties: {},
      pointsList: [{
        x: 800,
        y: 475
      }, {
        x: 800,
        y: 285
      }]
    }, {
      id: '19e38fb6-0522-40a3-84af-a3b2c78ff4ce',
      type: 'polyline',
      sourceNodeId: '2803f38e-d738-486d-9ceb-6978ebfe499e',
      targetNodeId: '8966ac10-90b0-4b8c-b513-d2df04e6a28f',
      startPoint: {
        x: 1100,
        y: 625
      },
      endPoint: {
        x: 1100,
        y: 285
      },
      properties: {},
      pointsList: [{
        x: 1100,
        y: 625
      }, {
        x: 1100,
        y: 285
      }]
    }, {
      id: 'b0ae7f48-bd78-4355-91e7-54010e505bb2',
      type: 'polyline',
      sourceNodeId: 'c38d0048-bfc3-42b2-9143-9e057488a478',
      targetNodeId: '19055d13-917f-406a-96d7-e760f989c261',
      startPoint: {
        x: 2050,
        y: 475
      },
      endPoint: {
        x: 2050,
        y: 285
      },
      properties: {},
      pointsList: [{
        x: 2050,
        y: 475
      }, {
        x: 2050,
        y: 285
      }]
    }, {
      id: '232527f7-8457-4ec0-ad65-709a2ca8820d',
      type: 'polyline',
      sourceNodeId: '39f3b4b0-461b-4640-b70d-b12f4b844bf5',
      targetNodeId: 'f6833fd0-aad7-4b6c-9230-f36557fce898',
      startPoint: {
        x: 2350,
        y: 625
      },
      endPoint: {
        x: 2350,
        y: 285
      },
      properties: {},
      pointsList: [{
        x: 2350,
        y: 625
      }, {
        x: 2350,
        y: 285
      }]
    }, {
      id: 'e42867b0-99c9-4ce1-91c4-aa4ed56867df',
      type: 'polyline',
      sourceNodeId: '2159e68f-6834-4601-8cb9-4b2d908988fa',
      targetNodeId: '52a311e3-e9aa-43b3-8b4d-2674c44d8383',
      startPoint: {
        x: 2650,
        y: 475
      },
      endPoint: {
        x: 2650,
        y: 285
      },
      properties: {},
      pointsList: [{
        x: 2650,
        y: 475
      }, {
        x: 2650,
        y: 285
      }]
    }]
  }
})
