<template>
  <a-card style="margin-bottom: 10px">
    <span slot="title"><a-icon type="info-circle" style="color: orange"/> 说明</span>
    <slot></slot>
  </a-card>
</template>

<script>
export default {
  name: 'ComponentDescription'
}
</script>

<style scoped>

</style>
