import { render, staticRenderFns } from "./ComponentDescription.vue?vue&type=template&id=3518ae19&scoped=true&"
import script from "./ComponentDescription.vue?vue&type=script&lang=js&"
export * from "./ComponentDescription.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3518ae19",
  null
  
)

export default component.exports