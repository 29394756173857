<template>
  <div>
    <a-row>
      <a-col :span="12">
        <div style="font-size: 16px;line-height: 30px">
          <div>
            TS+Vue3实现移步<a href="https://vue3-demo.gudu.fun/" target="_blank">https://vue3-demo.gudu.fun/</a>查看相关内容。
          </div>
          <div>整理归纳总结工作中遇到的Vue问题的解决方案。</div>
          <div>技术栈如下：</div>
          <ul>
            <li>vue 2.6.14</li>
            <li>vuex 3.6.2</li>
            <li>vue-ls 4.2.0</li>
            <li>ant-design-vue 1.7.8</li>
            <li>mockjs 1.1.0</li>
            <li>jest 27.0.5</li>
            <li>vue-grid-layout 2.4.0</li>
            <li>html2canvas 1.4.1</li>
            <li>logicflow 1.2.9</li>
            <li>gsap 3.11.5</li>
            <li>vue2-ace-editor 0.0.15</li>
            <li>brace 0.11.1</li>
          </ul>
        </div>
      </a-col>
      <a-col :span="12">
        <div style="font-weight: bolder;font-size: 16px">更新日志</div>
        <a-timeline style="margin-top: 10px" pending="持续更新中...">
          <a-timeline-item v-for="(item, index) in timelineList" :key="index">
            【{{ item.time }}】 {{ item.desc }}
          </a-timeline-item>
        </a-timeline>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'IndexView',
  data () {
    return {
      timelineList: [
        {
          time: '2023-04-25',
          desc: '开始创建，并增加VueGridLayout。'
        },
        {
          time: '2023-04-26',
          desc: '加入Mockjs、递归组件和内置组件，加入说明和代码查看功能。'
        },
        {
          time: '2023-04-27',
          desc: '加入动态组件。'
        },
        {
          time: '2023-05-20',
          desc: '加入OnlyOffice。'
        },
        {
          time: '2023-06-01',
          desc: '加入Html2Canvas。'
        },
        {
          time: '2023-06-22',
          desc: '加入LogicFlow。'
        },
        {
          time: '2023-07-10',
          desc: '加入Gsap。'
        },
        {
          time: '2023-07-11',
          desc: '加入Flex。'
        },
        {
          time: '2023-07-15',
          desc: '加入Vuex。'
        },
        {
          time: '2023-07-20',
          desc: '加入执行自定义代码。'
        },
        {
          time: '2023-07-20',
          desc: '加入AceEditor。'
        },
        {
          time: '2023-07-22',
          desc: '加入自动调整超出范围。'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
